exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-adocao-js": () => import("./../../../src/pages/adocao.js" /* webpackChunkName: "component---src-pages-adocao-js" */),
  "component---src-pages-contato-js": () => import("./../../../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-frentes-de-atuacao-js": () => import("./../../../src/pages/frentes-de-atuacao.js" /* webpackChunkName: "component---src-pages-frentes-de-atuacao-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nossa-ong-js": () => import("./../../../src/pages/nossa-ong.js" /* webpackChunkName: "component---src-pages-nossa-ong-js" */),
  "component---src-pages-quero-ajudar-js": () => import("./../../../src/pages/quero-ajudar.js" /* webpackChunkName: "component---src-pages-quero-ajudar-js" */),
  "component---src-templates-adocao-js": () => import("./../../../src/templates/adocao.js" /* webpackChunkName: "component---src-templates-adocao-js" */)
}

