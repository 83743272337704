import { Link } from 'gatsby'
import React from 'react'
import './footer.scss'

const Footer = () => {
  return (
    <footer className="background-yellow py-2">
      <div className="container ">
        <hr className='font-gray mt-4' style={{ opacity: 'unset' }}></hr>
        <div className='row  pt-2 font-gray'>
          <div className='col-lg col-12 centralize-text'>
            <div className='col-12'><p className='mt-0 mb-2'><b>Menu</b></p></div>
            <div className='col-12 d-flex justify-content-lg-start justify-content-center'>
              <ul className='list-unstyled'>
                <li className="">
                  <Link className='underline-hover text-decoration-none font-gray' to="/">Início</Link>
                </li>
                <li className="">
                  <Link className='underline-hover text-decoration-none font-gray' to="/nossa-ong">Nossa ONG</Link>
                </li>
                <li className="">
                  <Link className='underline-hover text-decoration-none font-gray' to="/adocao">Adoção</Link>
                </li>
                <li className="">
                  <Link className='underline-hover text-decoration-none font-gray' to="/frentes-de-atuacao">Frentes de atuação</Link>
                </li>
                <li className="">
                  <Link className='underline-hover text-decoration-none font-gray' to="/quero-ajudar">Quero Ajudar</Link>
                </li>
                <li className="">
                  <Link className='underline-hover text-decoration-none font-gray' to="/contato">Contato</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className='col-lg col-12 text-center pb-lg-0 pb-3'>
            <p className='text-center pt-2 my-0'><b>© {new Date().getFullYear()} - ONG Canto da Terra</b></p>
            <div className='m-0  p-0 col-12'><p className='underline-hover my-0'><b><a className='text-decoration-none font-gray' href='mailto:ongcantodaterra@gmail.com'>ongcantodaterra@gmail.com</a></b></p></div>
            <div className='m-0  p-0 col-12'><p className='text-decoration-underline my-0'><b><a className='text-decoration-none font-gray' aria-label="Chat on WhatsApp" href="https://wa.me/+5511970553947">(11) 970553947</a></b></p></div>
            <div className='m-0  p-0 col-12'><p className='my-0'><b>CNPJ: 24.807.320/0001-16</b></p></div>
          </div>
          <div className='col-lg col-12 text-lg-end text-center d-flex justify-content-lg-end justify-content-center pb-lg-0 pb-4'>
            <div>
              <p className='my-0 pt-2'><b>Design por: Thaís Dias</b></p>
              <p><b>Desenvolvido por: Lucas Torino</b></p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
