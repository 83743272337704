import { Link } from 'gatsby'
import { useLocation } from '@reach/router';
import React, { useEffect, useState } from 'react'
import logo from '../../images/icon/logo-cantodaterra.svg'
import './header.scss'

const Header = () => {
  const [isNavOpen, setIsNavOpen] = useState(false)

  const handleToggleNav = () => {
    setIsNavOpen(!isNavOpen)
  }

  const location = useLocation();

  useEffect(() => {
    setIsNavOpen(false)

  }, [location]) // Add 'isSizeHidden' to the dependency array

  return (
    <nav className="navbar navbar-expand-lg py-lg-3 py-2 background-yellowwish">
      <div className="container">
        <Link className="navbar-brand font-brown" to="/">
          <img src={logo} width={129} alt='icon facebook' className='me-2 d-lg-block d-none' />
          <img src={logo} width={110} alt='icon facebook' className='me-2 d-lg-none d-block' />
        </Link>
        <button
          className={`navbar-toggler ${isNavOpen ? 'collapsed' : ''}`}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={handleToggleNav}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`collapse navbar-collapse ${isNavOpen ? 'show' : ''}`} id="navbarNav">
          <div className='ms-auto py-lg-0 py-3'>
            <ul className="navbar-nav ms-auto">
              <li className="nav-item px-xl-2">
                <Link className={`nav-link ${location.pathname === '/' ? 'font-green fw-bold' : ' font-gray'}`} to="/">Início</Link>
              </li>
              <li className="nav-item px-xl-2">
                <Link className={`nav-link ${location.pathname === '/nossa-ong/' ? 'font-green fw-bold' : ' font-gray'}`} to="/nossa-ong">Nossa ONG</Link>
              </li>
              <li className="nav-item px-xl-2">
                <Link className={`nav-link ${location.pathname === '/adocao/' ? 'font-green fw-bold' : ' font-gray'}`} to="/adocao">Adoção</Link>
              </li>
              <li className="nav-item px-xl-2">
                <Link className={`nav-link ${location.pathname === '/frentes-de-atuacao/' ? 'font-green fw-bold' : ' font-gray'}`} to="/frentes-de-atuacao">Frentes de atuação</Link>
              </li>
              <li className="nav-item px-xl-2">
                <Link className={`nav-link ${location.pathname === '/quero-ajudar/' ? 'font-green fw-bold' : ' font-gray'}`} to="/quero-ajudar">Quero Ajudar</Link>
              </li>
              <li className="nav-item px-xl-2">
                <Link className={`nav-link ${location.pathname === '/contato/' ? 'font-green fw-bold' : ' font-gray'}`} to="/contato">Contato</Link>
              </li>
              <li className="nav-item ps-lg-2 pt-lg-0 pt-2">
                <a href='https://catarse.me/cantodaterra' target="_blank" rel="noreferrer">
                  <button className='btn btn-green'>Faça uma doação</button>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Header
